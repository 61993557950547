<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="圖片" align="center">
        <template slot-scope="scope">
          <img
            style="margin: auto;"
            width="90px"
            :src="scope.row.image"
            alt=""
          >
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="姓名" prop="name" align="center" />
      <BaseElTableColumn label="隸屬分店" prop="shop" align="center" />
      <BaseElTableColumn label="服務項目" prop="services" align="center" />
      <BaseElTableColumn label="服務年資" prop="serviceYears" align="center" />
      <BaseElTableColumn label="整體績效" prop="totalStar" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="查看"
            @edit="onRowDetail(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { get, map, join } from 'lodash'
import { imgSrc } from '@/utils/helper'
import { useRouter } from 'vue-router/composables'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'DataTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here

      // serviceYears: 服務年資，計算 createdAt 到目前為止的天數
      const serviceYears = (createdAt) => {
        const now = dayjs()
        const start = dayjs(createdAt)
        const diff = now.diff(start, 'days')
        const years = Math.floor(diff / 365)
        const days = diff % 365
        return years ? `${years} 年 ${days} 天` : `${days} 天`
      }

      return map(data, (item) => {
        return {
          id: get(item, 'appointmentUnit.id'),
          shop: get(item, 'appointmentUnit.Shop.name'),
          image: imgSrc(300, get(item, 'appointmentUnit.Image')),
          name: get(item, 'appointmentUnit.name'),
          services: join(map(get(item, 'appointmentUnit.AppointmentServices'), 'name'), '、') || '-',
          serviceYears: serviceYears(get(item, 'appointmentUnit.createdAt')),
          totalStar: get(item, 'totalStar').toFixed(1),
        }
      })
    })

    const onRowDetail = (row) => {
      router.push({
        name: 'OrgBeautyAIAppointmentUnitAnalysisDetail',
        params: { id: row.id },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    return {
      modal,
      displayData,
      onRowDetail,
      onRowDelete,
    }
  },
})
</script>
